import {
  fontFamily,
  fontSize,
  breakpoint,
  color,
  lineHeight,
} from '@aib/design-tokens';
import styled from 'styled-components';
import connectIcon from '../../Theme/assets/images/illustration-connect.svg';
import Button from '@aib/uikit-react/dist/Form/Button/Button';

export const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StepContainer = styled.div`
  align-self: left;
  font-size: ${fontSize.small};
  color: ${color.grey07};
  max-width: 314px;
  @media (min-width: ${breakpoint.small}) {
    max-width: 370px;
  }
`;

export const TopParagraph = styled.div`
  @media (min-width: ${breakpoint.small}) {
    max-width: 370px;
  }
  font-family: ${fontFamily.medium};
  font-size: 14px;
  line-height: 22px;
`;

export const ListItem = styled.div`
  font-family: ${fontFamily.medium};
`;

export const ListItemBold = styled.div`
  font-family: ${fontFamily.bold};
`;

export const ItalicsParagraph = styled.div`
  font-family: ${fontFamily.italic}, ${fontFamily.medium};
  @media (min-width: ${breakpoint.small}) {
    max-width: 370px;
    width: 100%;
  }
`;

export const GotItButton = styled(Button)`
  align-self: center;
  max-width: 314px;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 3rem;
`;

export const BoldSentence = styled.div`
  font-family: ${fontFamily.bold};
  margin-bottom: 0.5rem;
  font-size: 16px;
`;

export const StyledLink = styled.a`
  color: ${color.corePurple100};
`;

export const ListWrapper = styled.div`
  align-self: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ListsWrapper = styled.div`
  align-self: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const ConnectIcon = styled.div`
  background-image: url(${connectIcon});
  background-size: cover;
  height: 150px;
  width: 150px;
  align-self: center;
`;

export const AccordionGroup = styled.div`
  border-bottom: 1px solid ${color.grey04};
`;

export const AccordionTitle = styled.div`
  font-size: ${fontSize.medium};
  font-family: 'aspira-medium', sans-serif;
  line-height: ${lineHeight.medium};
  color: ${color.corePurple100};
  margin: 0 0 1rem 0;
`;

export const AccordionParagraph = styled.div`
  position: relative;
  margin: 1em 0 0 0;
  font-family: 'aspira-medium', sans-serif;
  display: flex;
  justify-content: ${(props) => props.align || undefined};

  &:first-of-type {
    margin-top: 0;
  }
`;

export const EmbeddedInfoPopup = styled.div`
  margin-top: -44px;

  @media (min-width: ${breakpoint.large}) {
    margin-left: 115px;
    margin-right: 115px;
  }
`;
